import { useContext } from "react"
import contextStore from "./context"

const useLogout = () => {
  const { setContext } = useContext(contextStore)

  return () => {
    setContext(state => ({
      ...state,
      currentAdmin: null,
    }))
  }
}

export default useLogout
