import React from "react"
import { CircularProgress, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
  },
})

const Loading = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress />
      <Typography variant="subtitle1">Admin-Backend wird geladen.</Typography>
    </div>
  )
}

export default Loading
