import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"

// returns the date for beginning of tomorrow
const tomorrowDate = () =>
  moment()
    .startOf("day")
    .add(1, "days")

const ApprovalModal = ({ open, onCancel, onApproval, loading }) => {
  const [publishDate, setPublishDate] = useState(new Date())

  const handleApproval = () => {
    onApproval(publishDate)
  }

  const handleCancel = () => {
    setPublishDate(new Date())
    onCancel()
  }

  useEffect(() => {
    if (open) {
      setPublishDate(new Date())
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>Veröffentlichungsdatum wählen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Es sind nun alle Daten vorhanden. Der Veröffentlichung kann also
          nichts mehr im Weg stehen.
        </DialogContentText>
        <DatePicker
          value={publishDate}
          onChange={setPublishDate}
          variant="static"
          minDate={tomorrowDate()}
          disableFuture={loading}
        />
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size="1em" />}
        <Button onClick={handleCancel} color="primary" disabled={loading}>
          Abbrechen
        </Button>
        <Button onClick={handleApproval} color="primary" disabled={loading}>
          Genehmigen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ApprovalModal.defaultProps = {
  loading: false,
}

ApprovalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onApproval: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default ApprovalModal
