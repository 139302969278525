export const getPromotionTypeString = pt => {
  if (pt === "MIXED_PACKAGE") {
    return "Mixkarton"
  }

  if (pt === "DISPLAY") {
    return "Display"
  }

  if (pt === "BONUS") {
    return "Aktion"
  }

  return "Unknown status"
}
