import { gql } from "@apollo/client"

export default gql`
  query GetAllReviewableShippingUnits {
    allShippingUnits(filter: { state: SUBMITTED_FOR_REVIEW }) {
      id
      name
      promotionType
      state
      purchasePrice
      depositValue
      depositType
      image {
        ...image
      }
      ... on StandardShippingUnit {
        gtin
        supplierItemNumber
        physicalDimensions {
          ...dimensions
        }
        weightKg
        packaging
      }
      ... on MixedShippingUnit {
        gtin
        supplierItemNumber
        physicalDimensions {
          ...dimensions
        }
        weightKg
        packaging
      }
      ... on BonusShippingUnit {
        orderableFrom
        orderableTo
      }
      ... on DisplayShippingUnit {
        gtin
        supplierItemNumber
        physicalDimensions {
          ...dimensions
        }
        weightKg
        weightExclPackagingKg
      }
      startup {
        id
        name
      }
    }
  }

  fragment dimensions on PhysicalDimensions {
    heightCm
    widthCm
    lengthCm
  }

  fragment image on Image {
    orderIndex
    sources(only: [ORIGINAL, THUMBNAIL]) {
      sizeClass
      uri
    }
  }
`
