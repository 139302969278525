import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const ListContainer = ({ children, loading, errorMessage }) => {
  const classes = useStyles()

  const showError = !loading && errorMessage
  const showItems = !loading && !errorMessage
  const showLoading = loading

  return (
    <div className={classes.root}>
      {showError && (
        <>
          <Alert severity="error">
            <b>Server Error</b>
            <br />
            <pre>{errorMessage}</pre>
          </Alert>
          <div className={classes.content}>
            <p>Bitte wende Dich an das foodstarter Tech team.</p>
          </div>
        </>
      )}
      <div className={classes.content}>
        {showItems && children}
        {showLoading && <CircularProgress />}
      </div>
    </div>
  )
}

ListContainer.defaultProps = {
  errorMessage: null,
}

ListContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
}

export default ListContainer
