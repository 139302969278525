import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
import { useSnackbar } from "material-ui-snackbar-provider"
import allReviewableShippingUnitsQuery from "../../../../graphql/queries/allReviewableShippingUnitsQuery"
import ShippingUnitTableRow from "./ShippingUnitTableRow"
import RejectionModal from "../../../shared/RejectionModal"
import ApprovalModal from "../../../shared/ApprovalModal"
import useApproval from "../../../shared/useApproval"
import useRejection from "../../../shared/useRejection"
import rejectShippingUnitMutation from "./rejectShippingUnitMutation"
import approveShippingUnitMutation from "./approveShippingUnitMutation"

const ShippingUnitTable = ({ shippingUnits, requestUpdate }) => {
  const showErrorMessage = errors =>
    showMessage(`:( Error: ${errors.map(error => error.message).join(", ")}`)

  const {
    openApprovalDialog,
    loadingApproval,
    handleApproval,
    setOpenApprovalId,
  } = useApproval({
    mutation: approveShippingUnitMutation,
    showErrorMessage,
  })
  const {
    openRejectionDialog,
    loadingRejection,
    setOpenRejectionId,
    handleRejection,
  } = useRejection({
    mutation: rejectShippingUnitMutation,
    query: allReviewableShippingUnitsQuery,
    showErrorMessage,
  })

  const { showMessage } = useSnackbar()

  const handleCloseDialogs = () => {
    setOpenRejectionId(null)
    setOpenApprovalId(null)
  }

  // sets the context of the open dialog to the clicked row
  const handleRejectionRequest = shippingUnitId => () => {
    setOpenRejectionId(shippingUnitId)
  }

  const handleApprovalRequest = shippingUnitId => () => {
    setOpenApprovalId(shippingUnitId)
  }

  const onHandleRejection = async (message, allowCorrection) => {
    await handleRejection(message, allowCorrection, () => {
      handleCloseDialogs()
    })
  }

  const onHandleApproval = async publishDate => {
    await handleApproval(publishDate, () => {
      handleCloseDialogs()
      requestUpdate()
    })
  }

  return (
    <>
      <Grid container spacing={2} alignItems="stretch" direction="column">
        {shippingUnits.map(shippingUnit => (
          <ShippingUnitTableRow
            key={shippingUnit.id}
            shippingUnit={shippingUnit}
            onRejection={handleRejectionRequest(shippingUnit.id)}
            onApproval={handleApprovalRequest(shippingUnit.id)}
          />
        ))}
      </Grid>
      <RejectionModal
        open={openRejectionDialog}
        onCancel={handleCloseDialogs}
        onRejection={onHandleRejection}
        loading={loadingRejection}
      >
        Gib einen genauen Grund an, warum Du die Versandeinheit noch nicht
        zulassen konntest und was das Startup genau machen muss, um die
        Genehmigung zu erhalten.
      </RejectionModal>
      <ApprovalModal
        open={openApprovalDialog}
        onCancel={handleCloseDialogs}
        onApproval={onHandleApproval}
        loading={loadingApproval}
      />
    </>
  )
}

ShippingUnitTable.propTypes = {
  shippingUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  requestUpdate: PropTypes.func.isRequired,
}

export default ShippingUnitTable
