import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@material-ui/core"
import ExpandableLayout from "../ExandableLayout"
import DetailsTable from "./DetailsTable"

const ShippingUnitDetails = ({ shippingUnit }) => {
  const isShippingUnitNull = !shippingUnit

  return (
    <ExpandableLayout headline="Standard Versandeinheit">
      {isShippingUnitNull ? (
        <Typography>Keine Standardversandeinheit angelegt</Typography>
      ) : (
        <DetailsTable shippingUnit={shippingUnit} />
      )}
    </ExpandableLayout>
  )
}

ShippingUnitDetails.defaultProps = {
  shippingUnit: null,
}

ShippingUnitDetails.propTypes = {
  shippingUnit: PropTypes.shape({}),
}

export default ShippingUnitDetails
