import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Card, CardContent, CardActions, Button } from "@material-ui/core"
import PreviewImages from "components/PreviewImages"
import ShippingUnitShortInformation from "./ShippingUnitShortInformation"
import ShippingUnitDetails from "./ShippingUnitDetails"

const useStyles = makeStyles(theme => ({
  previewImage: {
    maxHeight: "160px",
    borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.grey[100]}`,
  },
  cautionButton: {
    color: theme.palette.error.dark,
  },
  approveButton: {
    color: theme.palette.success.dark,
  },
  alignRight: {
    float: "right",
  },
}))

const ShippingUnitTableRow = ({ shippingUnit, onApproval, onRejection }) => {
  const classes = useStyles()

  return (
    <Grid item>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                direction="column"
              >
                <Grid item>
                  <PreviewImages images={[shippingUnit.image]} />
                </Grid>
                <Grid item>
                  <ShippingUnitShortInformation {...shippingUnit} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                direction="column"
              >
                <Grid item>
                  <ShippingUnitDetails shippingUnit={shippingUnit} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.alignRight}>
          <Button className={classes.cautionButton} onClick={onRejection}>
            Ablehnen
          </Button>
          <Button className={classes.approveButton} onClick={onApproval}>
            Genehmigen
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

ShippingUnitTableRow.propTypes = {
  shippingUnit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.shape({}).isRequired,
  }).isRequired,
  onApproval: PropTypes.func.isRequired,
  onRejection: PropTypes.func.isRequired,
}

export default ShippingUnitTableRow
