import React from "react"
import { Switch, Redirect } from "react-router-dom"

import { RouteWithLayout } from "components"
import { Main as MainLayout, Minimal as MinimalLayout } from "layouts"

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Account as AccountView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ProductDraftList as ProductDraftListView,
  ShippingUnitList as ShippingUnitListView,
} from "views"
import { useAppContext } from "components/AppContext"

const Routes = () => {
  const { currentAccount, loadingApp } = useAppContext()
  const notLoggedIn = currentAccount === null

  if (loadingApp) {
    return null
  }

  if (notLoggedIn) {
    return (
      <Switch>
        <RouteWithLayout
          component={SignInView}
          exact
          layout={MinimalLayout}
          path="/sign-in"
        />
        <Redirect to="/sign-in" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/merchants"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/startups"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={ProductDraftListView}
        exact
        layout={MainLayout}
        path="/product-drafts"
      />
      <RouteWithLayout
        component={ShippingUnitListView}
        exact
        layout={MainLayout}
        path="/shipping-units"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
