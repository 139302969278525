import { shippingUnitPromotionTypes } from "common/constants"
import { isNil } from "lodash/fp"
import moment from "moment"
import { floatToEur } from "./currency"

const numberFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  useGrouping: true, // thousands separator
})

export const formatDeposit = ({ depositType, depositValue }) => {
  const parsedValue = Number.parseFloat(depositValue)

  if (!Number.isFinite(parsedValue)) {
    return "Kein Pfand"
  }

  const valueOnly = floatToEur(parsedValue)

  if (typeof depositType === "string" && depositType.length > 0) {
    return `${valueOnly} (${depositType})`
  }

  return valueOnly
}

export const formatShippingUnitPromotionType = promotionType =>
  ({
    [shippingUnitPromotionTypes.MIXED_PACKAGE]: "Mixkarton",
    [shippingUnitPromotionTypes.DISPLAY]: "Display",
    [shippingUnitPromotionTypes.BONUS]: "Aktion",
  }[promotionType] || "Unbekannte Promotion")

export const formatAgeRestriction = age => {
  if (age === null) {
    return "Keine Einschränkung"
  }

  return `ab ${age} Jahren`
}

export const formatDimensions = dimensions => {
  if (isNil(dimensions)) {
    return "Keine Angaben"
  }

  const { heightCm, lengthCm, widthCm } = dimensions

  return [
    heightCm ? `↑ ${heightCm} cm` : null,
    widthCm ? `→ ${widthCm} cm` : null,
    lengthCm ? `↗ ${lengthCm} cm` : null,
  ]
    .filter(Boolean)
    .join(", ")
}

export const formatQuantityWithUnit = (quantity, unit) => {
  if (!quantity || !unit) {
    return "Keine Angaben"
  }

  return [numberFormatter.format(quantity), unit].join(" ")
}

export const formatTimeRange = (from, to) => {
  const fromDate = isNil(from) ? moment.invalid() : moment.utc(from)
  const toDate = isNil(to) ? moment.invalid() : moment.utc(to)

  return (
    [
      fromDate.isValid() ? `ab ${fromDate.format("L")}` : null,
      toDate.isValid() ? `bis ${toDate.format("L")}` : null,
    ]
      .filter(Boolean)
      .join(", ") || "Uneingeschränkt"
  )
}
