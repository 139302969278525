import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Drawer } from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
// import PeopleIcon from "@material-ui/icons/People"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
// import AccountBoxIcon from "@material-ui/icons/AccountBox"
// import SettingsIcon from "@material-ui/icons/Settings"

import { SidebarNav } from "./components"

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = props => {
  const { open, variant, onClose, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Produktanträge",
      href: "/product-drafts",
      icon: <ShoppingBasketIcon />,
    },
    {
      title: "Versandeinheitenanträge",
      href: "/shipping-units",
      icon: <LocalShippingIcon />,
    },
    // {
    //   title: "Kaufleute",
    //   href: "/merchants",
    //   icon: <PeopleIcon />,
    // },
    // {
    //   title: "Startups",
    //   href: "/startups",
    //   icon: <PeopleIcon />,
    // },
    // {
    //   title: "Profil",
    //   href: "/account",
    //   icon: <AccountBoxIcon />,
    // },
    // {
    //   title: "Einstellungen",
    //   href: "/settings",
    //   icon: <SettingsIcon />,
    // },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={classes.root}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.defaultProps = {
  onClose: () => {},
}

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
