import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import { getOr } from "lodash/fp"
import Context from "./context"
import currentSessionQuery from "./currentSessionQuery"

const AppContext = ({ children, loadingView }) => {
  const [appContext, setAppContext] = useState({
    currentAccount: null,
    loadingApp: true,
  })

  const { loading, data } = useQuery(currentSessionQuery)

  const currentAccount = getOr(null, "session.account", data)

  useEffect(() => {
    setAppContext(state => ({
      ...state,
      currentAccount,
      loadingApp: loading,
    }))
  }, [currentAccount, loading])

  if (loading) {
    return loadingView
  }

  return (
    <Context.Provider
      value={{ context: appContext, setContext: setAppContext }}
    >
      {children}
    </Context.Provider>
  )
}

AppContext.propTypes = {
  children: PropTypes.node.isRequired,
  loadingView: PropTypes.node.isRequired,
}

export default AppContext
