import { gql } from "@apollo/client"

export default gql`
  query ChecklistForProduct($productDraftId: ID!) {
    checklistForProductDraft(productDraftId: $productDraftId) {
      key
      message
    }
  }
`
