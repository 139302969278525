import cookies from "browser-cookies"

const COOKIE_KEY = "auth_token"
const defaultCookieOptions = {
  samesite: "Strict",
  expires: 365,
  secure: process.env.NODE_ENV !== "development",
}

export default class AuthStorageAccessor {
  static readToken() {
    return cookies.get(COOKIE_KEY)
  }

  static writeToken(token) {
    cookies.set(COOKIE_KEY, token, defaultCookieOptions)
  }

  static removeToken() {
    cookies.erase(COOKIE_KEY)
  }
}
