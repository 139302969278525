const checked = (value, options) => {
  if (value !== true) {
    return options.message || "muss ausgewählt werden"
  }

  return undefined
}

export default {
  checked,
}
