import { useContext } from "react"
import AuthStorageAccessor from "services/authStorage"
import contextStore from "./context"

const useLogin = () => {
  const { setContext } = useContext(contextStore)

  return session => {
    AuthStorageAccessor.writeToken(session.jwtToken)

    setContext(state => ({
      ...state,
      currentAccount: session.account,
    }))
  }
}

export default useLogin
