import React from "react"
import PropTypes from "prop-types"
import { Typography, Link } from "@material-ui/core"

const ProductShortInformation = ({
  displayName,
  startupName,
  generalInformation,
}) => {
  return (
    <>
      <Typography variant="h5">
        {displayName || "Kein Name vergeben"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link href="/">{startupName}</Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {generalInformation || "Keine Beschreibung vergeben"}
      </Typography>
    </>
  )
}

ProductShortInformation.defaultProps = {
  displayName: null,
  generalInformation: null,
}

ProductShortInformation.propTypes = {
  displayName: PropTypes.string,
  startupName: PropTypes.string.isRequired,
  generalInformation: PropTypes.string,
}

export default ProductShortInformation
