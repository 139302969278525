export const getStatusMessage = status => {
  if (status === "SUBMITTED_FOR_REVIEW") {
    return "Submitted for approval"
  }

  if (status === "INITIAL") {
    return "Draft"
  }

  if (status === "REJECTED") {
    return "Rejected"
  }

  return "Unknown status"
}
