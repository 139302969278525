import { gql } from "@apollo/client"

export default gql`
  mutation LoginWithCredentials($email: String!, $password: String!) {
    loginWithCredentials(input: { email: $email, password: $password }) {
      denialReason
      session {
        jwtToken
        account {
          id
          email
        }
      }
    }
  }
`
