import React from "react"
import PropTypes from "prop-types"
import { truncate } from "lodash/fp"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined"

const useStyles = makeStyles(theme => ({
  fileItemContainer: {
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    marginRight: theme.spacing(),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    "& p": {
      paddingTop: theme.spacing(0.5),
    },
  },
}))

const truncName = truncate({ length: 15 })
const classificationNameFromMeta = meta => {
  const classificationItem = meta.find(m => m.key === "classification")

  if (!classificationItem) {
    return "Keine Zuordnung"
  }

  switch (classificationItem.value) {
    case "BIO_CERT":
      return "Bio-Zertifikat"
    case "DECLARATION_CHECK":
      return "Deklarationsprüfung"
    case "MANUFACTURER_CERT":
      return "Herstellerzertifizierung"
    case "LAB_REPORT":
      return "Laborbericht"
    case "CERT_OF_FREE_SALE":
      return "Verkehrsfähigkeitsbescheinigung"
    case "MISCELLANEOUS":
      return "Weitere"
    default:
      // eslint-disable-next-line no-console
      console.warn(`Do not know classification "${classificationItem.value}"`)
      return classificationItem.value || "UNKLASSIFIZIERT"
  }
}

const DocumentList = ({ documents }) => {
  const classes = useStyles()

  const handleClick = uri => () => {
    window.open(uri, "_blank", "noopener noreferrer")
  }

  return documents.map(doc => (
    <div
      role="link"
      key={doc.downloadUri}
      className={classes.fileItemContainer}
      onClick={handleClick(doc.downloadUri)}
      onKeyPress={handleClick(doc.downloadUri)}
      tabIndex={0}
    >
      <InsertDriveFileOutlinedIcon />
      <Typography variant="caption" component="p">
        {truncName(classificationNameFromMeta(doc.meta))}
      </Typography>
    </div>
  ))
}

DocumentList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      classification: PropTypes.string,
      downloadUri: PropTypes.string,
    })
  ).isRequired,
}

export default DocumentList
