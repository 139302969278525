import React from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import { Chart } from "react-chartjs-2"
import { ThemeProvider } from "@material-ui/core/styles"
import { AppContext } from "components/AppContext"
import validate from "validate.js"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import "moment/locale/de"
import MomentUtils from "@date-io/moment"
import { SnackbarProvider } from "material-ui-snackbar-provider"

import Loading from "views/Loading"
import { chartjs } from "./helpers"
import theme from "./theme"
import "react-perfect-scrollbar/dist/css/styles.css"
import validators from "./common/validators"
import Routes from "./Routes"
import GraphQlProvider from "./GraphQlProvider"

const browserHistory = createBrowserHistory()

const pickerLocale = "de"

// tell moment that monday is the start of the week
moment.locale(pickerLocale)

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
})

validate.validators = {
  ...validate.validators,
  ...validators,
}

const App = () => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider
      SnackbarProps={{
        autoHideDuration: 4000,
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      }}
    >
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={pickerLocale}
      >
        <GraphQlProvider>
          <AppContext loadingView={<Loading />}>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </AppContext>
        </GraphQlProvider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </ThemeProvider>
)

export default App
