import React from "react"
import PropTypes from "prop-types"
import { Typography, List, ListItem, ListItemText } from "@material-ui/core"
import { isEmpty } from "lodash/fp"
import ExpandableLayout from "./ExandableLayout"

const Checklist = ({ entries }) => {
  const hasNoEntries = isEmpty(entries)

  const headline = `Checkliste ${hasNoEntries ? "🟢" : "🔴"}`

  return (
    <ExpandableLayout headline={headline}>
      {hasNoEntries ? (
        <Typography>
          All clear{" "}
          <span aria-label="ok" role="img">
            👌
          </span>
        </Typography>
      ) : (
        <List dense>
          {entries.map(({ message, key }) => (
            <ListItem key={key}>
              <ListItemText primary={message} />
            </ListItem>
          ))}
        </List>
      )}
    </ExpandableLayout>
  )
}

Checklist.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Checklist
