import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import { defaultTo, filter, get, head, pipe } from "lodash/fp"

const useStyles = makeStyles(theme => ({
  previewImage: {
    maxHeight: "100px",
    borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.grey[100]}`,
  },
}))

const getThumbnail = pipe(
  get("sources"),
  filter({ sizeClass: "THUMBNAIL" }),
  head,
  defaultTo({})
)

const PreviewImages = ({ images }) => {
  const classes = useStyles()

  let sortedImages = [...images]
  sortedImages = sortedImages?.sort((a, b) => a.orderIndex < b.orderIndex)

  return (
    <Grid container spacing={1} direction="row">
      {sortedImages.map(getThumbnail).map(image => (
        <Grid item key={image.uri}>
          <img
            className={classes.previewImage}
            src={image.uri}
            alt="Produktbild"
          />
        </Grid>
      ))}
    </Grid>
  )
}

PreviewImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      sources: PropTypes.arrayOf(
        PropTypes.shape({
          orderIndex: PropTypes.number,
          uri: PropTypes.string.isRequired,
          sizeClass: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
}

export default PreviewImages
