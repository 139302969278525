import { gql } from "@apollo/client"

export default gql`
  mutation RejectProductDraft(
    $id: ID!
    $reason: String!
    $allowUpdate: Boolean!
  ) {
    rejectProductDraft(
      input: { productDraftId: $id, reason: $reason, allowUpdate: $allowUpdate }
    ) {
      id
      state
    }
  }
`
