import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"

const RejectionModal = ({ open, onCancel, onRejection, loading, children }) => {
  const [rejectionMessage, setRejectionMessage] = useState("")
  const [allowCorrection, setAllowCorrection] = useState(true)

  const handleRejection = () => {
    onRejection(rejectionMessage, allowCorrection)
  }

  const handleAllowCorrectionToggle = event => {
    setAllowCorrection(event.target.checked)
  }

  const handleMessageUpdate = event => {
    setRejectionMessage(event.target.value)
  }

  const handleClose = () => {
    setRejectionMessage("")
    onCancel()
  }

  useEffect(() => {
    if (open) {
      setRejectionMessage("")
    }
  }, [open])

  const isRejectionMessageEmpty = rejectionMessage.length === 0
  const isDisableRejectButton = isRejectionMessageEmpty || loading

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
    >
      <DialogTitle>Grund der Ablehnung</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowCorrection}
              onChange={handleAllowCorrectionToggle}
              name="correctable"
              color="primary"
            />
          }
          label="Startup erlauben die Angaben zu korrigieren"
        />
        <TextField
          autoFocus
          multiline
          margin="dense"
          rows={5}
          variant="outlined"
          type="textarea"
          fullWidth
          value={rejectionMessage}
          onChange={handleMessageUpdate}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size="1em" />}
        <Button onClick={handleClose} color="primary" disabled={loading}>
          Abbrechen
        </Button>
        <Button
          onClick={handleRejection}
          color="primary"
          disabled={isDisableRejectButton}
        >
          Ablehnen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RejectionModal.defaultProps = {
  loading: false,
}

RejectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRejection: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default RejectionModal
