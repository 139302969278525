import { gql } from "@apollo/client"

export default gql`
  mutation RejectShippingUnit(
    $id: ID!
    $reason: String!
    $allowUpdate: Boolean!
  ) {
    rejectShippingUnit(
      input: { shippingUnitId: $id, reason: $reason, allowUpdate: $allowUpdate }
    ) {
      id
      state
    }
  }
`
