import { useState } from "react"
import { useMutation } from "@apollo/client"
import Rollbar from "rollbar"

function useApproval({ mutation, showErrorMessage }) {
  const [openApprovalId, setOpenApprovalId] = useState(null)
  const [loadingApproval, setLoadingApproval] = useState(false)
  const [approve] = useMutation(mutation)
  const openApprovalDialog = openApprovalId !== null
  const rollbar = new Rollbar({
    enabled: process.env.NODE_ENV !== "production",
    accessToken: "f53c6496447a47278c99dba1890480a2",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
  })

  const handleApproval = async (publishDate, callback) => {
    setLoadingApproval(true)

    try {
      const { errors, data } = await approve({
        variables: {
          id: openApprovalId,
          publishOn: publishDate,
        },
      })

      if (errors) {
        rollbar.error(errors, { callback })
        showErrorMessage(errors)
      }

      const { approveShippingUnit, approveProductDraft } = data
      if (!approveShippingUnit && !approveProductDraft) {
        showErrorMessage([
          {
            message: "Ein unbekannter Fehler ist beim Genehmigen aufgetreten.",
          },
        ])
      }

      setLoadingApproval(false)
      callback()
    } catch (error) {
      rollbar.error(error, { callback })
      showErrorMessage([{ message: error }])
      setLoadingApproval(false)
      callback()
    }
  }

  return {
    openApprovalDialog,
    loadingApproval,
    handleApproval,
    setOpenApprovalId,
  }
}

export default useApproval
