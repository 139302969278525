import { gql } from "@apollo/client"

export default gql`
  query GetCurrentSession {
    session {
      jwtToken
      account {
        email
        id
      }
    }
  }
`
