import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useMutation } from "@apollo/client"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined"
import InputIcon from "@material-ui/icons/Input"
import { useLogout } from "components/AppContext"
import logoutMutation from "./logoutMutation"

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.white,
    paddingLeft: theme.spacing(1),
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    height: theme.spacing(5),
  },
}))

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()

  const [notifications] = useState([])

  const [logout] = useMutation(logoutMutation)
  const resetAdmin = useLogout()

  const handleLogout = () => {
    logout()
    resetAdmin()
  }

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/edk-logo.png" className={classes.logo} />
        </RouterLink>
        <div className={classes.flexGrow}>
          <Typography variant="h4" className={classes.title}>
            foodstarter admin
          </Typography>
        </div>
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.defaultProps = {
  className: null,
  onSidebarOpen: () => {},
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
