import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash/fp"
import { Grid } from "@material-ui/core"
import { useSnackbar } from "material-ui-snackbar-provider"
import RejectionModal from "../../../shared/RejectionModal"
import ApprovalModal from "../../../shared/ApprovalModal"
import useApproval from "../../../shared/useApproval"
import useRejection from "../../../shared/useRejection"
import ProductDraftTableRow from "./ProductDraftTableRow"
import rejectProductDraftMutation from "./rejectProductDraftMutation"
import approveProductDraftMutation from "./approveProductDraftMutation"
import allReviewableProductDraftQuery from "../../../../graphql/queries/allReviewableProductDraftQuery"

const flattenProductDraft = productDraft => ({
  ...productDraft,
  category: get("category.name", productDraft),
  packaging: get("packaging.name", productDraft),
  physicalDimensionsHeightCm: get("physicalDimensions.heightCm", productDraft),
  physicalDimensionsLengthCm: get("physicalDimensions.lengthCm", productDraft),
  physicalDimensionsWidthCm: get("physicalDimensions.widthCm", productDraft),
  salesUnit: get("salesUnit.name", productDraft),
  startupName: get("startup.name", productDraft),
})

const ProductDraftTable = ({ productDrafts, requestUpdate }) => {
  const showErrorMessage = errors =>
    showMessage(`:( Error: ${errors.map(error => error.message).join(", ")}`)

  const {
    openApprovalDialog,
    loadingApproval,
    handleApproval,
    setOpenApprovalId,
  } = useApproval({
    mutation: approveProductDraftMutation,
    showErrorMessage,
  })
  const {
    openRejectionDialog,
    loadingRejection,
    setOpenRejectionId,
    handleRejection,
  } = useRejection({
    mutation: rejectProductDraftMutation,
    query: allReviewableProductDraftQuery,
    showErrorMessage,
  })

  const { showMessage } = useSnackbar()

  const handleCloseDialogs = () => {
    setOpenRejectionId(null)
    setOpenApprovalId(null)
  }

  // sets the context of the open dialog to the clicked row
  const handleApprovalRequest = productDraftId => () => {
    setOpenApprovalId(productDraftId)
  }

  // sets the context of the open dialog to the clicked row
  const handleRejectionRequest = productDraftId => () => {
    setOpenRejectionId(productDraftId)
  }

  const onHandleRejection = async (message, allowCorrection) => {
    await handleRejection(message, allowCorrection, () => {
      handleCloseDialogs()
    })
  }

  const onHandleApproval = async publishDate => {
    await handleApproval(publishDate, () => {
      handleCloseDialogs()
      requestUpdate()
    })
  }

  return (
    <>
      <Grid container spacing={2} alignItems="stretch" direction="column">
        {productDrafts.map(flattenProductDraft).map(productDraft => (
          <ProductDraftTableRow
            key={productDraft.id}
            productDraft={productDraft}
            onRejection={handleRejectionRequest(productDraft.id)}
            onApproval={handleApprovalRequest(productDraft.id)}
          />
        ))}
      </Grid>
      <RejectionModal
        open={openRejectionDialog}
        onCancel={handleCloseDialogs}
        onRejection={onHandleRejection}
        loading={loadingRejection}
      >
        Gib einen genauen Grund an, warum Du das Produkt noch nicht zulassen
        konntest und was das Startup genau machen muss, um die Genehmigung zu
        erhalten.
      </RejectionModal>
      <ApprovalModal
        open={openApprovalDialog}
        onCancel={handleCloseDialogs}
        onApproval={onHandleApproval}
        loading={loadingApproval}
      />
    </>
  )
}

ProductDraftTable.propTypes = {
  productDrafts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      startupName: PropTypes.string,
      generalInformation: PropTypes.string,
    })
  ).isRequired,
  requestUpdate: PropTypes.func.isRequired,
}

export default ProductDraftTable
