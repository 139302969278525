import { useContext } from "react"
import contextStore from "./context"

const useAppContext = () => {
  const { context } = useContext(contextStore)

  return context
}

export default useAppContext
