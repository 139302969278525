import React from "react"
import PropTypes from "prop-types"
import { TableBody, Table, TableRow, TableCell } from "@material-ui/core"
import { floatToEur } from "formatter/currency"
import {
  formatDeposit,
  formatDimensions,
  formatQuantityWithUnit,
  formatTimeRange,
} from "formatter/product"
import { shippingUnitPromotionTypes } from "common/constants"

const showRowForPromoType = promotionType => attribute => {
  const showAttribute = {
    GTIN: [false, true, true],
    SUPPLIER_ITEM_NUMBER: [false, true, true],
    WEIGHT_KG: [false, true, true],
    WEIGHT_EXCL_PACKAGING_KG: [false, true, false],
    PHYSICAL_DIMENSIONS: [false, true, true],
    ORDERABLE_RANGE: [true, false, false],
  }[attribute]

  if (!showAttribute) {
    return true
  }

  switch (promotionType) {
    case shippingUnitPromotionTypes.BONUS:
      return showAttribute[0]
    case shippingUnitPromotionTypes.DISPLAY:
      return showAttribute[1]
    case shippingUnitPromotionTypes.MIXED_PACKAGE:
      return showAttribute[2]
    default:
      return true
  }
}

const ShippingUnitDetails = ({ shippingUnit }) => {
  const showRowForAttribute = showRowForPromoType(shippingUnit.promotionType)

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>{shippingUnit.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>{shippingUnit.name}</TableCell>
        </TableRow>
        {showRowForAttribute("GTIN") && (
          <TableRow>
            <TableCell>GTIN</TableCell>
            <TableCell>{shippingUnit.gtin}</TableCell>
          </TableRow>
        )}
        {showRowForAttribute("SUPPLIER_ITEM_NUMBER") && (
          <TableRow>
            <TableCell>Lieferanten-Artikel-Nr.</TableCell>
            <TableCell>{shippingUnit.supplierItemNumber}</TableCell>
          </TableRow>
        )}
        {showRowForAttribute("WEIGHT_KG") && (
          <TableRow>
            <TableCell>Gewicht (inkl. Verpackung)</TableCell>
            <TableCell>
              {formatQuantityWithUnit(shippingUnit.weightKg, "kg")}
            </TableCell>
          </TableRow>
        )}
        {showRowForAttribute("WEIGHT_EXCL_PACKAGING_KG") && (
          <TableRow>
            <TableCell>Gewicht (ohne Verpackung)</TableCell>
            <TableCell>
              {formatQuantityWithUnit(shippingUnit.weightExclPackagingKg, "kg")}
            </TableCell>
          </TableRow>
        )}
        {showRowForAttribute("PHYSICAL_DIMENSIONS") && (
          <TableRow>
            <TableCell>Größe</TableCell>
            <TableCell>
              {formatDimensions(shippingUnit.physicalDimensions)}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Pfand</TableCell>
          <TableCell>{formatDeposit(shippingUnit)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Einkaufspreis</TableCell>
          <TableCell>{floatToEur(shippingUnit.purchasePrice)}</TableCell>
        </TableRow>
        {showRowForAttribute("ORDERABLE_RANGE") && (
          <TableRow>
            <TableCell>Bestellbarkeitszeitraum</TableCell>
            <TableCell>
              {formatTimeRange(
                shippingUnit.orderableFrom,
                shippingUnit.orderableTo
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
ShippingUnitDetails.propTypes = {
  shippingUnit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    promotionType: PropTypes.oneOf(Object.values(shippingUnitPromotionTypes))
      .isRequired,
    name: PropTypes.string.isRequired,
    gtin: PropTypes.string,
    supplierItemNumber: PropTypes.string,
    weightKg: PropTypes.number,
    weightExclPackagingKg: PropTypes.number,
    physicalDimensions: PropTypes.shape({
      lengthCm: PropTypes.number.isRequired,
      heightCm: PropTypes.number.isRequired,
      widthCm: PropTypes.number.isRequired,
    }),
    purchasePrice: PropTypes.number.isRequired,
    orderableFrom: PropTypes.string,
    orderableTo: PropTypes.string,
  }).isRequired,
}

export default ShippingUnitDetails
