import React from "react"
import { useQuery } from "@apollo/client"
import { Typography } from "@material-ui/core"
import { get, pipe, reject } from "lodash/fp"
import ListContainer from "layouts/ListContainer"
import ShippingUnitTable from "./components/ShippingUnitTable"
import allReviewableShippingUnitsQuery from "../../graphql/queries/allReviewableShippingUnitsQuery"

const ShippingUnitList = () => {
  const { loading, data, error, refetch } = useQuery(
    allReviewableShippingUnitsQuery
  )

  const displayShippingUnits = pipe(
    get("allShippingUnits"),
    reject({ promotionType: "STANDARD" })
  )(data)

  const showShippingUnits = displayShippingUnits.length > 0
  const showNoShippingUnits = displayShippingUnits.length === 0
  const errorMessage = error ? error.message : null

  const handleUpdateRequest = () => refetch()

  return (
    <ListContainer loading={loading} errorMessage={errorMessage}>
      {showNoShippingUnits && (
        <Typography>Keine Versandeinheitenanträge</Typography>
      )}
      {showShippingUnits && (
        <ShippingUnitTable
          shippingUnits={displayShippingUnits}
          requestUpdate={handleUpdateRequest}
        />
      )}
    </ListContainer>
  )
}

export default ShippingUnitList
