import React, { useState, useEffect } from "react"
import validate from "validate.js"
import { useMutation } from "@apollo/client"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core"
import { useLogin } from "components/AppContext"
import loginWithCredentialsMutation from "./loginWithCredentialsMutation"

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/hero.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))

const SignIn = () => {
  const classes = useStyles()
  const [loginAdminByCredentials] = useMutation(loginWithCredentialsMutation)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  const history = useHistory()
  const login = useLogin()

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSignIn = async event => {
    event.preventDefault()
    const { email, password } = formState.values

    const result = await loginAdminByCredentials({
      variables: {
        email,
        password,
      },
    })

    const { session, denialReason } = result.data.loginWithCredentials
    const loginSuccess = session !== null

    if (loginSuccess) {
      login(session)
      history.push("/dashboard")
      return
    }

    const errorMessage = {
      ACCOUNT_LOCKED: "Dein Account wurde gesperrt",
      INVALID_CREDENTIALS: "Email oder Passwort falsch",
    }[denialReason]

    setFormState(values => ({
      ...values,
      errors: {
        ...values.errors,
        login: errorMessage || "Unbekannter fehler",
      },
    }))
  }

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field])

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Wir verbinden was zusammen gehört.
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  foodstarter Backend
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("email")}
                  fullWidth
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("password")}
                  fullWidth
                  helperText={
                    hasError("password") ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ""}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>
                {formState.errors.login && (
                  <FormHelperText error>
                    {formState.errors.login}
                  </FormHelperText>
                )}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignIn
