import React from "react"
import PropTypes from "prop-types"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import AuthStorageAccessor from "services/authStorage"

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_HOST,
})

const authLink = setContext((_, { headers }) => {
  const token = AuthStorageAccessor.readToken()

  if (!token) {
    return { headers }
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const GraphQlProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

GraphQlProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GraphQlProvider
