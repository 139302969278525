import React from "react"
import PropTypes from "prop-types"
import { TableBody, Table, TableRow, TableCell } from "@material-ui/core"
import { floatToEur } from "formatter/currency"
import {
  formatAgeRestriction,
  formatDeposit,
  formatDimensions,
} from "formatter/product"
import ExpandableLayout from "./ExandableLayout"

const ProductDraftDetails = ({ productDraft }) => (
  <ExpandableLayout headline="Produktdetails">
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>{productDraft.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Marke</TableCell>
          <TableCell>{productDraft.brand}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Produktart</TableCell>
          <TableCell>{productDraft.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Sorte</TableCell>
          <TableCell>{productDraft.option}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Biozertifiziert</TableCell>
          <TableCell>{productDraft.bio ? "🟢" : "⚪️"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Laktosefrei</TableCell>
          <TableCell>{productDraft.lactoseFree ? "🟢" : "⚪️"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Glutenfrei</TableCell>
          <TableCell>{productDraft.glutenFree ? "🟢" : "⚪️"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Lieferanten-Artikel-Nr.</TableCell>
          <TableCell>{productDraft.supplierItemNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>GTIN</TableCell>
          <TableCell>{productDraft.gtin}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Größe</TableCell>
          <TableCell>
            {formatDimensions({
              widthCm: productDraft.physicalDimensionsWidthCm,
              lengthCm: productDraft.physicalDimensionsLengthCm,
              heightCm: productDraft.physicalDimensionsHeightCm,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gewicht des Produktes</TableCell>
          <TableCell>{productDraft.weightGrams} g</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Verpackungsart</TableCell>
          <TableCell>{productDraft.packaging}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Inhalt</TableCell>
          <TableCell>
            {productDraft.salesQuantity} {productDraft.salesUnit}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Alkoholgehalt</TableCell>
          <TableCell>
            {productDraft.alcoholContentPercent || "--"} Vol%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Pfand</TableCell>
          <TableCell>{formatDeposit(productDraft)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Warengruppe</TableCell>
          <TableCell>{productDraft.category}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Mindesthaltbarkeit</TableCell>
          <TableCell>{productDraft.minimumShelfLifeDays} Tage</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Altersfreigabe</TableCell>
          <TableCell>
            {formatAgeRestriction(productDraft.ageRestriction)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>MwSt.</TableCell>
          <TableCell>
            {Math.round((productDraft.vatRate || 0) * 100)} %
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Einkaufspreis</TableCell>
          <TableCell>{floatToEur(productDraft.purchasePrice)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>UVP</TableCell>
          <TableCell>{floatToEur(productDraft.grossPrice)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Öko-Kontrollstellen-Nummer</TableCell>
          <TableCell>
            {productDraft.regulatedOrganicProductId || "Keine Angabe"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </ExpandableLayout>
)

ProductDraftDetails.propTypes = {
  productDraft: PropTypes.shape({
    id: PropTypes.string,
    brand: PropTypes.string,
    option: PropTypes.string,
    name: PropTypes.string,
    bio: PropTypes.bool,
    lactoseFree: PropTypes.bool,
    glutenFree: PropTypes.bool,
    supplierItemNumber: PropTypes.string,
    gtin: PropTypes.string,
    physicalDimensionsHeightCm: PropTypes.number,
    physicalDimensionsLengthCm: PropTypes.number,
    physicalDimensionsWidthCm: PropTypes.number,
    weightGrams: PropTypes.number,
    packaging: PropTypes.string,
    salesUnit: PropTypes.string,
    salesQuantity: PropTypes.number,
    alcoholContentPercent: PropTypes.number,
    depositValue: PropTypes.number,
    category: PropTypes.string,
    vatRate: PropTypes.number,
    purchasePrice: PropTypes.number,
    grossPrice: PropTypes.number,
    minimumShelfLifeDays: PropTypes.number,
    ageRestriction: PropTypes.number,
    regulatedOrganicProductId: PropTypes.string,
  }).isRequired,
}

export default ProductDraftDetails
