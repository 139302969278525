import React from "react"
import { useQuery } from "@apollo/client"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import { getOr } from "lodash/fp"
import allReviewableProductDraftQuery from "graphql/queries/allReviewableProductDraftQuery"
import { Link } from "react-router-dom"
import { getStatusMessage } from "../../common/getStatusMessage"

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  content: {
    padding: 0,
  },
  image: {
    objectFit: "cover",
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: "flex-end",
  },
}))

const ProductDraftsTable = props => {
  const { className, ...rest } = props

  const classes = useStyles()

  const { loading, data, error } = useQuery(allReviewableProductDraftQuery)

  const productDrafts = getOr([], "allProductDrafts", data)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>There was an error fetching the product drafts...</div>
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // subtitle={`${products.length} in total`}
        title="Latest product drafts"
      />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>GTIN</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Startup</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productDrafts.map(product => (
              <TableRow hover key={product.id}>
                <TableCell>{product.gtin}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.startup.name}</TableCell>
                <TableCell>{getStatusMessage(product.state)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Link to="/product-drafts">
          <Button color="primary" size="small" variant="text">
            View all <ArrowRightIcon />
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

ProductDraftsTable.defaultProps = {
  className: null,
}

ProductDraftsTable.propTypes = {
  className: PropTypes.string,
}

export default ProductDraftsTable
