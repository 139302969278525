import { useState } from "react"
import { useMutation } from "@apollo/client"
import Rollbar from "rollbar"

function useRejection({ mutation, query, showErrorMessage }) {
  const [openRejectionId, setOpenRejectionId] = useState(null)
  const [loadingRejection, setLoadingRejection] = useState(false)
  const [reject] = useMutation(mutation, {
    refetchQueries: [{ query }],
  })
  const openRejectionDialog = openRejectionId !== null
  const rollbar = new Rollbar({
    enabled: process.env.NODE_ENV !== "production",
    accessToken: "f53c6496447a47278c99dba1890480a2",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
  })

  const handleRejection = async (message, allowCorrection, callback) => {
    setLoadingRejection(true)

    try {
      const { errors } = await reject({
        variables: {
          id: openRejectionId,
          reason: message,
          allowUpdate: allowCorrection,
        },
      })

      if (errors) {
        rollbar.error(errors, { callback })
        showErrorMessage(errors)
      }

      setLoadingRejection(false)
      callback()
    } catch (error) {
      rollbar.error(error, { callback })
      showErrorMessage([{ message: error }])
      setLoadingRejection(false)
      callback()
    }
  }

  return {
    openRejectionDialog,
    loadingRejection,
    setOpenRejectionId,
    handleRejection,
  }
}

export default useRejection
