import React from "react"
import { useQuery } from "@apollo/client"
import { getOr } from "lodash/fp"
import { Typography } from "@material-ui/core"
import ListContainer from "layouts/ListContainer"
import { ProductDraftTable } from "./components"
import allReviewableProductDraftQuery from "../../graphql/queries/allReviewableProductDraftQuery"

const ProductDraftList = () => {
  const { loading, data, error, refetch } = useQuery(
    allReviewableProductDraftQuery
  )

  const displayProductDrafts = getOr([], "allProductDrafts", data)

  const showProducts = displayProductDrafts.length > 0
  const showNoProducts = displayProductDrafts.length === 0
  const errorMessage = error ? error.message : null

  const handleUpdateRequest = () => refetch()

  return (
    <ListContainer errorMessage={errorMessage} loading={loading}>
      {showNoProducts && <Typography>Keine Produktanträge</Typography>}
      {showProducts && (
        <ProductDraftTable
          productDrafts={displayProductDrafts}
          requestUpdate={handleUpdateRequest}
        />
      )}
    </ListContainer>
  )
}

export default ProductDraftList
