import React from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Card, CardContent, CardActions, Button } from "@material-ui/core"
import { get, find, defaultTo, pipe, map, getOr } from "lodash/fp"
import PreviewImages from "components/PreviewImages"
import DocumentList from "../DocumentList"
import ProductShortInformation from "./ProductShortInformation"
import { ProductDraftDetails, ShippingUnitDetails } from "./expandables"
import Checklist from "./expandables/Checklist"
import checklistForProductQuery from "./checklistForProductQuery"

const useStyles = makeStyles(theme => ({
  cautionButton: {
    color: theme.palette.error.dark,
  },
  approveButton: {
    color: theme.palette.success.dark,
  },
  alignRight: {
    float: "right",
  },
}))

const getStandardShippingUnit = pipe(
  get("shippingUnitLinks"),
  map(link => ({ ...link.shippingUnit, quantity: link.quantity })),
  find({ promotionType: "STANDARD" }),
  defaultTo(null)
)

const flattenShippingUnit = shippingUnit => ({
  ...shippingUnit,
  physicalDimensionsHeightCm: get("physicalDimensions.heightCm", shippingUnit),
  physicalDimensionsLengthCm: get("physicalDimensions.lengthCm", shippingUnit),
  physicalDimensionsWidthCm: get("physicalDimensions.widthCm", shippingUnit),
})

const ProductDraftTableRow = ({ productDraft, onApproval, onRejection }) => {
  const classes = useStyles()
  const standardShippingUnit = getStandardShippingUnit(productDraft)
  const { loading, data } = useQuery(checklistForProductQuery, {
    variables: { productDraftId: productDraft.id },
  })

  const checklistEntries = loading
    ? []
    : getOr([], "checklistForProductDraft", data)

  return (
    <Grid item key={productDraft.id}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                direction="column"
              >
                <Grid item>
                  <PreviewImages images={productDraft.images} />
                </Grid>
                <Grid item>
                  <ProductShortInformation {...productDraft} />
                </Grid>
                <Grid item>
                  <DocumentList documents={productDraft.documents} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                direction="column"
              >
                <Grid item>
                  <ProductDraftDetails productDraft={productDraft} />
                </Grid>
                <Grid item>
                  <ShippingUnitDetails
                    shippingUnit={flattenShippingUnit(standardShippingUnit)}
                  />
                </Grid>
                <Grid item>
                  <Checklist entries={checklistEntries} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.alignRight}>
          <Button className={classes.cautionButton} onClick={onRejection}>
            Ablehnen
          </Button>
          <Button className={classes.approveButton} onClick={onApproval}>
            Genehmigen
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

ProductDraftTableRow.propTypes = {
  productDraft: PropTypes.shape({
    id: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.object),
    images: PropTypes.arrayOf(PropTypes.object),
    shippingUnits: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onApproval: PropTypes.func.isRequired,
  onRejection: PropTypes.func.isRequired,
}

export default ProductDraftTableRow
