import React from "react"
import PropTypes from "prop-types"
import { Typography, Link } from "@material-ui/core"
import { formatShippingUnitPromotionType } from "formatter/product"

const ShippingUnitShortInformation = ({ name, promotionType, startup }) => {
  const { name: startupName } = startup

  return (
    <>
      <Typography variant="h5">{name}</Typography>
      <Typography variant="body1" gutterBottom>
        <Link href="/">{startupName}</Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {formatShippingUnitPromotionType(promotionType)}
      </Typography>
    </>
  )
}

ShippingUnitShortInformation.propTypes = {
  name: PropTypes.string.isRequired,
  promotionType: PropTypes.string.isRequired,
  startup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ShippingUnitShortInformation
