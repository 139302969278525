import { gql } from "@apollo/client"

export default gql`
  query GetAllSubmittedProductDrafts {
    allProductDrafts(filter: { state: SUBMITTED_FOR_REVIEW }) {
      id
      brand
      name
      option
      bio
      lactoseFree
      glutenFree
      generalInformation
      supplierItemNumber
      gtin
      minimumShelfLifeDays
      ageRestriction
      physicalDimensions {
        heightCm
        lengthCm
        widthCm
      }
      weightGrams
      packaging {
        name
      }
      salesUnit {
        name
      }
      salesQuantity
      alcoholContentPercent
      depositValue
      depositType
      state
      category {
        name
      }
      startup {
        name
        email
      }
      vatRate
      purchasePrice
      grossPrice
      images {
        orderIndex
        sources(only: [ORIGINAL, THUMBNAIL]) {
          sizeClass
          uri
        }
      }
      displayName
      documents {
        meta {
          key
          value
        }
        downloadUri
        originalFilename
      }
      shippingUnitLinks {
        quantity
        shippingUnit {
          id
          state
          promotionType
          depositValue
          depositType
          purchasePrice
          image {
            sources(only: [THUMBNAIL, ORIGINAL]) {
              sizeClass
              uri
            }
          }
          ... on StandardShippingUnit {
            gtin
            physicalDimensions {
              heightCm
              widthCm
              lengthCm
            }
            weightKg
            supplierItemNumber
            packaging
          }
        }
      }
    }
  }
`
