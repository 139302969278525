import React from "react"
import PropTypes from "prop-types"
import {
  Grid,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core"
import { floatToEur } from "formatter/currency"
import { formatDeposit } from "formatter/product"
import PreviewImages from "components/PreviewImages"

const DetailsTable = ({ shippingUnit }) => (
  <Grid container spacing={2}>
    <Grid item>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>{shippingUnit.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Anzahl der Produkte</TableCell>
            <TableCell>{shippingUnit.quantity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Lieferanten-Artikel-Nr.</TableCell>
            <TableCell>{shippingUnit.supplierItemNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GTIN</TableCell>
            <TableCell>{shippingUnit.gtin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Größe</TableCell>
            <TableCell>
              (H) {shippingUnit.physicalDimensionsHeightCm} cm x (L){" "}
              {shippingUnit.physicalDimensionsLengthCm} cm x (W){" "}
              {shippingUnit.physicalDimensionsWidthCm} cm
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Gewicht des Produktes</TableCell>
            <TableCell>{shippingUnit.weightKg} Kg</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Einkaufspreis</TableCell>
            <TableCell>{floatToEur(shippingUnit.purchasePrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pfand</TableCell>
            <TableCell>{formatDeposit(shippingUnit)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Verpackung</TableCell>
            <TableCell>{shippingUnit.packaging}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
    <Grid item>
      {shippingUnit.image ? (
        <PreviewImages images={[shippingUnit.image]} />
      ) : (
        <Typography>
          <i>Kein Bild hochgeladen</i>
        </Typography>
      )}
    </Grid>
  </Grid>
)

DetailsTable.defaultProps = {
  shippingUnit: null,
}

DetailsTable.propTypes = {
  shippingUnit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    purchasePrice: PropTypes.number.isRequired,
    gtin: PropTypes.string.isRequired,
    depositValue: PropTypes.number,
    depositType: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    supplierItemNumber: PropTypes.string.isRequired,
    physicalDimensionsHeightCm: PropTypes.number.isRequired,
    physicalDimensionsLengthCm: PropTypes.number.isRequired,
    physicalDimensionsWidthCm: PropTypes.number.isRequired,
    weightKg: PropTypes.number.isRequired,
    packaging: PropTypes.string.isRequired,
    image: PropTypes.shape(),
  }),
}

export default DetailsTable
