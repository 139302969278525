import React from "react"
import PropTypes from "prop-types"
import {
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const ExpandableLayout = ({ children, headline }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{headline}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

ExpandableLayout.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default ExpandableLayout
