import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  content: {
    height: "100%",
  },
}))

const Minimal = props => {
  const { children } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  )
}

Minimal.defaultProps = {
  children: null,
}

Minimal.propTypes = {
  children: PropTypes.node,
}

export default Minimal
